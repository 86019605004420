import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonMenu, MenuController } from '@ionic/angular';

@Component({
  selector: 'cwt-menu-wrapper',
  templateUrl: './menu-wrapper.component.html',
  styleUrls: ['./menu-wrapper.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuWrapperComponent implements OnInit, OnDestroy {

  public opened = false;
  @Input() contentId: string;
  @Input() menuId: string;
  @Input() side: string;

  constructor(private menu: MenuController) { }

  ngOnInit(): void {
    this.menu.enable(true, this.menuId);
  }

  ngOnDestroy(): void {
    this.menu.enable(false, this.menuId);
  }

  public close() {
    this.menu.close(this.menuId);
  }

}
