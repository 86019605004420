// @index(['./!(*.spec).ts', './*/index.ts'], f => `export * from '${f.path.replace(/\/index$/, '')}';`)
export * from './alert.service';
export * from './connection-handler.service';
export * from './error-handler.service';
export * from './ionic.module';
export * from './is-platform.directive';
export * from './logout.directive';
export * from './message.service';
export * from './paginated-store-infinite-scroll.directive';
export * from './select-locale.directive';
export * from './toggle-menu.directive';
// @endindex