import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'cwt-icon-modal-wrapper',
  templateUrl: './icon-modal-wrapper.component.html',
  styleUrls: ['./icon-modal-wrapper.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClearModalWrapperComponent implements OnInit {

  @Input() icon: string;

  constructor(private modal: ModalController) { }

  ngOnInit(): void {
  }

  close() {
    this.modal.dismiss();
  }

}
