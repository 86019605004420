import { Directive, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, hasSomeChanges } from '@cawita/core-front';
import { Product } from '@shared/models';
import { Subscription } from 'rxjs';
import { MarketStore } from '../market.store';

export type ProductsForTypeContext = {
  $implicit: Product[];
  cwtProductsForType: Product[];
}

@Directive({
  selector: '[cwtProductsForType]'
})
export class ProductsForTypeDirective implements OnChanges, OnInit {
  private _ctx: ProductsForTypeContext = { $implicit: [], cwtProductsForType: [] };
  private _view = new CwtDirectiveView(this.vcRef, this.tRef, this._ctx);
  private _sub: Subscription;

  @Input('cwtProductsForType') type: string;

  constructor(
    private vcRef: ViewContainerRef,
    private tRef: TemplateRef<ProductsForTypeContext>,
    private store: MarketStore
  ) { }

  ngOnInit(): void {
    this.store.init().subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSomeChanges(changes, ['type'], true)) this._subscribe();
  }

  private _subscribe() {
    this._sub?.unsubscribe();
    if (!this.type) return;
    this._sub = this.store.stableState$.subscribe((state) => {
      const products = state.perType?.[this.type] ?? [];

      console.log(state);
      this._ctx.$implicit = products;
      this._ctx.cwtProductsForType = products;
      this._view.ensureState(true);
    });
  }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
  }

}
