import { HttpContext } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Device } from '@capacitor/device';
import {
    ActionPerformed,
    PushNotifications,
    Token
} from '@capacitor/push-notifications';
import { isset } from '@cawita/core-front';
import { CwtApiFlags, CwtApiService } from '@cawita/core-front/api';
import { NotificationHandlerService } from '@user/services/notification-handler.service';
import { lastValueFrom } from 'rxjs';
import { Notification } from '../../../../../frontend-shared/src/lib/models';
import { CwtIonAlertService } from '../ionic';

@Injectable({ providedIn: 'root' })
export class PushNotificationService {
    private _fcmToken: string;

    constructor(
        private api: CwtApiService,
        private zone: NgZone,
        private alert: CwtIonAlertService,
        private handler: NotificationHandlerService,
    ) { }

    public async init() {
        const { platform } = await Device.getInfo();
        if (platform === 'web') return;

        const { receive } = await PushNotifications.checkPermissions();
        if (receive === 'denied') return;
        if (receive !== 'granted') {
            await this.alert.show({ title: 'alert.push-notification', message: `alert.push-notification-${platform}`, closeText: 'action.ok', });
            const { receive } = await PushNotifications.requestPermissions();
            if (receive !== 'granted') return;
        }
        try {
            PushNotifications.addListener('registration', this._onRegister);
            PushNotifications.addListener('pushNotificationActionPerformed', this._onAction);
            PushNotifications.register();
        } catch {
            return;
        }
    }

    private _onRegister = (token: Token) => {
        this._fcmToken = token.value;
        this.zone.run(() => this._registerToken());
    }

    private _onAction = (action: ActionPerformed) => {
        try {
            const data = action?.notification?.data;
            if (!isset(data)) return;
            const parsedData = {};
            for (const key in data) try { parsedData[key] = JSON.parse(data[key]); } catch (e) { }
            const notification = new Notification(parsedData);
            this.zone.run(() => this.handler.handle(notification));
        } catch (err) {
            //
        }
    }

    private async _registerToken() {
        await lastValueFrom(this.api.post('/firebase-token', { token: this._fcmToken }, {
            context: new HttpContext().set(CwtApiFlags.UseErrorHandler, false)
        }));
    }

}