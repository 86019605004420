import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView } from '@cawita/core-front';
import { Customer } from '@shared/models';
import { LocalizedAddress } from '@shared/models/localized-address.model';
import { pluck, Subscription } from 'rxjs';
import { ActiveAddressStore } from './active-address.store';

export type ActiveAddressContext = {
  cwtActiveAddress:LocalizedAddress;
  $implicit: LocalizedAddress;
}


@Directive({
  selector: '[cwtActiveAddress]'
})
export class ActiveAddressDirective implements OnInit, OnDestroy {
  private _ctx: ActiveAddressContext = { cwtActiveAddress: null, $implicit: null};
  private _view = new CwtDirectiveView(this.vcRef, this.tRef, this._ctx);
  private _sub: Subscription;

  constructor(
    private vcRef: ViewContainerRef,
    private tRef: TemplateRef<ActiveAddressContext>,
    private store: ActiveAddressStore
  ) { }

  ngOnInit(): void {
    
    this._sub = this.store.state$.subscribe((state) => {
      this._ctx.cwtActiveAddress = state.address;
      this._ctx.$implicit = state.address;
      this._view.ensureState(state.initialized);
    });
  }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
  }


}
