import { ChangeDetectionStrategy, Component, Input, OnInit, Optional, SkipSelf, ViewContainerRef } from '@angular/core';
import { IonHeader, IonModal } from '@ionic/angular';

@Component({
  selector: 'cwt-modal-header-icon',
  templateUrl: './modal-header-icon.component.html',
  styleUrls: ['./modal-header-icon.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalHeaderIconComponent implements OnInit {

  @Input() icon: string;

  constructor(
    @SkipSelf() @Optional() private modal: IonHeader,
    @SkipSelf() private vcRef: ViewContainerRef
  ) { }

  ngOnInit(): void {  }

}
