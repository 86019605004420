import { Inject, Injectable } from '@angular/core';
import { CwtCrudContract, CwtCrudService, mapPaginatedToArray } from '@cawita/core-front/api';
import { CwtAuthStore } from '@cawita/core-front/auth';
import { CwtStore } from '@cawita/core-front/state';
import { CustomerAddress } from '@shared/models';
import { LocalizedAddress } from '@shared/models/localized-address.model';
import { catchError, EMPTY, finalize, map, tap } from 'rxjs';
import { ActiveAddressPersistencyService } from './active-address-persistant.service';


export function initializeMyStore() {
    return (store: ActiveAddressStore) => {
        return () => new Promise<void>((res, rej) => {
            store.init().pipe(
                catchError(() => EMPTY),
                finalize(() => res())
            ).subscribe();
        })
    }
}

export type ActiveAddressState = {
    address: LocalizedAddress;
}

@Injectable({ providedIn: 'root' })
export class ActiveAddressStore extends CwtStore<ActiveAddressState> {
    constructor(
        private addressPersistencyService: ActiveAddressPersistencyService,
        private auth: CwtAuthStore,
        @Inject(CwtCrudContract.get(CustomerAddress, 'as-customer')) private customerAddressCrud: CwtCrudService<CustomerAddress>
    ) {
        super({
            initialized: false,
            loading: false,
            address: null,
        });
    }

    public refresh() {
        return this.init();
    }


    public init() {
        const activeAddressLS = this.addressPersistencyService.get('cwt-kaarlo-active-address');
        if (!!activeAddressLS?.coord) {
            this.setState(s => ({
                ...s,
                address: activeAddressLS,
                loading: false,
                initialized: true,
            }))
            return EMPTY;
        }

        if (!this.auth.isLoggedIn) {
            this.setState(s => ({ ...s, address: null, initialized: true, loading: false }))
            return EMPTY;
        }

        return this.customerAddressCrud.getAll().pipe(
            mapPaginatedToArray(),
            map((res: CustomerAddress[]) => res[0]),
            tap((res: CustomerAddress) => {
                if (res) {
                    const activeAddress = new LocalizedAddress({ address: res.address, coord: res.coord, isGeoloc: false });
                    this.addressPersistencyService.set('cwt-kaarlo-active-address', activeAddress);
                    this.setState(s => ({ ...s, address: activeAddress, loading: false, initialized: true }));
                } else {
                    this.setState(s => ({ ...s, address: null, loading: false, initialized: true }));
                }
            })
        )
    }

    public udpateAddress(address: LocalizedAddress) {
        this.addressPersistencyService.set('cwt-kaarlo-active-address', { ...address })
        this.setState(s => ({
            ...s,
            address
        }))
    }
}