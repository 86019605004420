import { Inject, Injectable } from '@angular/core';
import { CwtApiService, CwtCrudContract, CwtCrudService } from '@cawita/core-front/api';
import { MarketProvider, Product, Subscription, SubscriptionSubType } from '@shared/models';
import { lastValueFrom } from 'rxjs';
import { MarketManageSubscriptionService } from '../market-manage-subscription.service';
import { IAPMarketService } from './iap-market.service';

@Injectable({ providedIn: 'root' })
export class GoogleMarketService extends IAPMarketService {

    constructor(
        private api: CwtApiService,
        private manage: MarketManageSubscriptionService,
        @Inject(CwtCrudContract.get(Product, 'as-pro')) private productCrud: CwtCrudService<Product>
    ) {
        super();
    }

    getPlatform(): CdvPurchase.Platform {
        return CdvPurchase.Platform.GOOGLE_PLAY;
    }

    getProvider(): MarketProvider {
        return MarketProvider.Google;
    }

    getProducts(): Promise<Product[]> {
        return lastValueFrom(this.productCrud.getArray({ googleProduct: { ne: null } }));
    }

    getProductSKU(product: Product): string {
        return product?.googleProduct?.productId;
    }

    overridePrice(product: Product, iapProduct: CdvPurchase.Product): void {
        product.googleProduct.currency = iapProduct.pricing.currency;
        product.googleProduct.amount = iapProduct.pricing.priceMicros / 10_000;
    }

    async getReceipt(transaction: CdvPurchase.Transaction) {
        const adapter = this._store.getAdapter(this.getPlatform());
        const body = await adapter.receiptValidationBody(transaction.parentReceipt);
        const receiptTransaction = body.transaction;
        if (receiptTransaction.type !== CdvPurchase.Platform.GOOGLE_PLAY) return;
        const receipt = JSON.parse(receiptTransaction.receipt);
        return {
            packageName: receipt.packageName,
            productId: receipt.productId,
            purchaseToken: receipt.purchaseToken,
            subscription: receipt.autoRenewing
        };
    }

    async registerSubscription(product: Product, receipt: any): Promise<void> {
        await lastValueFrom(this.api.post(`google/subscription/success`, receipt));
    }

    async manageSubscription(sub: Subscription): Promise<void> {
        if (sub.subType === SubscriptionSubType.Google) return this.manage.manageImplicitIAP();
        if (sub.subType === SubscriptionSubType.Stripe) return this.manage.manageStripe();
        if (sub.subType === SubscriptionSubType.Apple) return this.manage.manageApple();
        return;
    }
}