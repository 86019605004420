import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Optional, Output, SkipSelf, ViewContainerRef } from '@angular/core';
import { IonHeader, IonModal } from '@ionic/angular';

@Component({
  selector: 'cwt-transparent-modal-wrapper',
  templateUrl: './transparent-modal-wrapper.component.html',
  styleUrls: ['./transparent-modal-wrapper.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransparentModalWrapperComponent implements OnInit {

  @Output() cancel = new EventEmitter();

  constructor(
    @SkipSelf() @Optional() private modal: IonHeader,
    @SkipSelf() private vcRef: ViewContainerRef
  ) { }

  ngOnInit(): void {
  }

}
