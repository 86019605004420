import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CwtI18nService } from '@cawita/core-front';
import { ICwtApiConnectionHandler } from '@cawita/core-front/api';
import { from, Observable } from 'rxjs';
import { CwtIonAlertService } from './alert.service';

@Injectable({ providedIn: 'root' })
export class CwtIonConnectionHandlerService implements ICwtApiConnectionHandler {
    constructor(
        private i18n: CwtI18nService,
        private alert: CwtIonAlertService
    ) { }

    handle(error: HttpErrorResponse): Observable<void> {
        return from(this._create());
    }

    private async _create() {
        await this.alert.show({
            title: 'alert.connection-title',
            message: 'alert.connection-title',
            closeText: 'action.retry'
        });
    }
}