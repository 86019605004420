import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppFormModule } from '@shared/modules';
import { CwtIonicModule } from 'frontend/user-app/src/app/modules/ionic';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { RateCustomerDialogComponent } from './rate-customer-dialog.component';
import { StarRatingModule } from '../star-rating/star-rating.module';
import { MobileLayoutModule } from '../../layout/layout.module';

@NgModule({
  declarations: [
    RateCustomerDialogComponent
  ],
  imports: [
    CommonModule,
    NzModalModule,
    CwtIonicModule,
    MobileLayoutModule,
    AppFormModule,
    StarRatingModule
  ],
  exports: [
    RateCustomerDialogComponent,
  ]
})
export class RateCustomerDialogModule { }
