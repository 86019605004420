import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppCommonModule } from '@shared/modules';
import { CwtMediaModule } from '@shared/modules/media/media.module';
import { CwtIonicModule } from '../../../modules/ionic';
import { CardWithIconComponent } from './card-with-icon/card-with-icon.component';
import { CardWithImageComponent } from './card-with-image/card-with-image.component';
import { CenteredPageComponent } from './centered-page/centered-page.component';
import { EmptyComponent } from './empty/empty.component';
import { GridListComponent } from './grid-list/grid-list.component';
import { GridPageComponent } from './grid-page/grid-page.component';
import { HorizontalScrolledListComponent } from './horizontal-scrolled-list/horizontal-scrolled-list.component';
import { ClearModalWrapperComponent } from './icon-modal-wrapper/icon-modal-wrapper.component';
import { LoadingComponent } from './loading/loading.component';
import { MainWrapperComponent } from './main-wrapper/main-wrapper.component';
import { WrapperContentDirective } from './main-wrapper/wrapper-content.directive';
import { WrapperFooterDirective } from './main-wrapper/wrapper-footer.directive';
import { WrapperHeaderDirective } from './main-wrapper/wrapper-header.directive';
import { MenuWrapperComponent } from './menu-wrapper/menu-wrapper.component';
import { ModalHeaderIconComponent } from './modal-header-icon/modal-header-icon.component';
import { SectionTitleComponent } from './section-title/section-title.component';
import { SimplePageComponent } from './simple-page/simple-page.component';
import { TransparentModalWrapperComponent } from './transparent-modal-wrapper/transparent-modal-wrapper.component';



@NgModule({
  declarations: [
    SimplePageComponent,
    SectionTitleComponent,
    MainWrapperComponent,
    MenuWrapperComponent,
    WrapperFooterDirective,
    WrapperHeaderDirective,
    WrapperContentDirective,
    CenteredPageComponent,
    CardWithIconComponent,
    CardWithImageComponent,
    HorizontalScrolledListComponent,
    GridListComponent,
    GridPageComponent,
    EmptyComponent,
    ModalHeaderIconComponent,
    ClearModalWrapperComponent,
    LoadingComponent,
    TransparentModalWrapperComponent
  ],
  imports: [
    CommonModule,
    CwtIonicModule,
    CwtMediaModule,
  ],
  exports: [
    AppCommonModule,
    SimplePageComponent,
    SectionTitleComponent,
    MainWrapperComponent,
    MenuWrapperComponent,
    WrapperFooterDirective,
    WrapperHeaderDirective,
    WrapperContentDirective,
    CenteredPageComponent,
    CardWithIconComponent,
    CardWithImageComponent,
    HorizontalScrolledListComponent,
    GridListComponent,
    GridPageComponent,
    EmptyComponent,
    ModalHeaderIconComponent,
    ClearModalWrapperComponent,
    LoadingComponent,
    TransparentModalWrapperComponent
  ]
})
export class MobileLayoutModule { }
