import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isset } from '@cawita/core-front';

@Component({
  selector: 'cwt-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.less'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => StarRatingComponent), multi: true }
  ]
})
export class StarRatingComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() maxValue = 5;
  @Input() disabled = false;
  @Input() value: number;
  @Input() size: number = 20;

  @Output() valueChange = new EventEmitter<number>();

  private _onChange = (value: number) => { };
  private _onTouched = () => { };

  public indices = [];

  constructor() { }

  ngOnInit(): void {
    this._updateIndices(this.maxValue);
    this._updateValue(this.value ?? 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { maxValue, value } = changes;
    if (isset(maxValue) && !maxValue.firstChange) this._updateIndices(this.maxValue);
    if (isset(value) && !value.firstChange) this._updateValue(this.value);
  }

  writeValue(value: number): void {
    if (this.disabled) return;

    this.value = value;
    this.valueChange.emit(value);
    this._onChange(value);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private _updateValue(value: number) {
    if (value > this.maxValue) this.value = this.maxValue;
    if (value < 0) this.value = 0;
  }

  private _updateIndices(maxValue: number) {
    for (let i = 0; i < maxValue; i++) this.indices.push(i);
  }
}
