import { Inject, Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { getId } from '@cawita/core-front';
import { CwtApiService, CwtCrudContract, CwtCrudService } from '@cawita/core-front/api';
import { lastValueFrom } from 'rxjs';
import { MarketProvider, Product, Subscription, SubscriptionSubType } from '@shared/models';
import { IMarketAdapter } from './market.adapter';
import { MarketManageSubscriptionService } from '../market-manage-subscription.service';

@Injectable({ providedIn: 'root' })
export class StripeMarketService implements IMarketAdapter {
    constructor(
        private api: CwtApiService,
        private manage: MarketManageSubscriptionService,
        @Inject(CwtCrudContract.get(Product, 'as-pro')) private productCrud: CwtCrudService<Product>
    ) { }

    getProvider(): MarketProvider {
        return MarketProvider.Stripe;
    }

    getProducts(): Promise<Product[]> {
        return lastValueFrom(this.productCrud.getArray({ stripeProduct: { ne: null } }));
    }

    async initialize(products: Product[]): Promise<void> { }
    async restore(): Promise<void> { return; }

    async purchase(product: Product): Promise<boolean> {
        try {
            const { url } = await lastValueFrom(this.api.post<any>(`/as-pro/mine/stripe/subscription/checkout`, { stripeProduct: getId(product?.stripeProduct) }));
            Browser.open({ url: url, windowName: '_self' });
            return true;
        } catch (err) {
            return false;
        }
    }

    async manageSubscription(sub: Subscription): Promise<void> {
        if (sub.subType === SubscriptionSubType.Stripe) return this.manage.manageStripe();
        if (sub.subType === SubscriptionSubType.Google) return this.manage.manageGoogle();
        if (sub.subType === SubscriptionSubType.Apple) return this.manage.manageApple();
        return;
    }

}