<ion-header>
    <ion-toolbar>
        <ion-title>{{'alert.strong-auth-title' | translate}}</ion-title>
    </ion-toolbar>
</ion-header>

<div class="inner-content">
    <cwt-simple-page>
        <ion-text>{{'alert.strong-auth-message' | translate}}</ion-text>
        <nz-form-item class="mt-3">
            <nz-form-control [nzErrorTip]="error">
                <nz-form-label>{{'field.password' | translate}}</nz-form-label>
                <input nz-input cwtPassword required [formControl]="password" type="password" autocomplete="password" />
            </nz-form-control>
        </nz-form-item>
        <div class="d-flex justify-content-end gap-3">
            <ion-button color="medium" fill="clear" (click)="cancel()">
                <ion-text>{{'action.cancel' | translate}}</ion-text>
            </ion-button>
            <ion-button color="dark" (click)="submit()">
                <ion-text>{{'action.submit' | translate}}</ion-text>
            </ion-button>
        </div>
    </cwt-simple-page>
</div>

<ng-template #error let-control>
    <span cwt-form-error [control]="control">
        <span *cwtFormError="'required'">{{'validation.required' | translate}}</span>
        <span *cwtFormError="'password'">{{'validation.invalid-password' | translate}}</span>
    </span>
</ng-template>