import { Component, Input } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { ProRate } from "@shared/models";

@Component({
  templateUrl: './rate-pro-dialog.component.html',
  styleUrls: ['./rate-pro-dialog.component.less'],
})
export class RateProDialogComponent {

  @Input() currentRate:ProRate;
  @Input() form: FormGroup = new FormGroup({
    rate: new FormGroup({
      professionalism: new FormControl<number | null>(5),
      qualityPrice: new FormControl<number | null>(5),
      quickness: new FormControl<number | null>(5),
      comment: new FormControl<string | null>(null)
    })
  });

  constructor(public modal: ModalController) { }

  ngOnInit(): void { 
    if(this.currentRate) this.form.patchValue({ rate:this.currentRate })
  }
}