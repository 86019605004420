<div class="d-flex justify-content-around">
    <div class="ion-activatable star-element" *ngFor="let index of indices" (click)="writeValue(index + 1)">
        <ion-icon
            [style.height.px]="size"
            [style.width.px]="size"
            [style.font-size.px]="size"
            [color]="value < (index + 1) ? 'dark' : 'primary'"
            [icon]="value < (index + 1) ? 'assets/img/star_empty.svg' : 'assets/img/star_full.svg'">
        </ion-icon>
        <ion-ripple-effect *ngIf="!disabled"></ion-ripple-effect>
    </div>
</div>