import { NgZone, inject } from '@angular/core';
import { getId, isset, sameId } from '@cawita/core-front';
import { Platform } from '@ionic/angular';
import { MarketProvider, Product, ProductType, Subscription } from '@shared/models';
import { Subject, firstValueFrom, from, lastValueFrom, take } from 'rxjs';
import { IMarketAdapter } from './market.adapter';
import { log } from 'console';

export abstract class IAPMarketService implements IMarketAdapter {
    private _lock: Record<string, boolean> = {};
    private _orders: Record<string, PendingOrder<Product>> = {};
    private _zone = inject(NgZone);

    protected _store: CdvPurchase.Store;
    protected _platform = inject(Platform);
    protected _map = new Map<string, Product>();

    private _orderObservers: Record<string, Subject<boolean>> = {};


    abstract getPlatform(): CdvPurchase.Platform;
    abstract getProvider(): MarketProvider;
    abstract getProductSKU(product: Product): string;
    abstract overridePrice(product: Product, iapProduct: CdvPurchase.Product): void;
    abstract getProducts(): Promise<Product[]>;
    abstract registerSubscription(product: Product, receipt: any): Promise<void>;
    abstract getReceipt(product: CdvPurchase.Transaction): Promise<any>;
    abstract manageSubscription(sub: Subscription): Promise<void>;

    async initialize(products: Product[]): Promise<Product[]> {
        await this._platform.ready();
        this._store = CdvPurchase.store;
        const platform = this.getPlatform();

        const subs = products.filter(s => s.type === ProductType.ProSubscription);
        this._registerSubscriptions(platform, subs);
        this._store.when().approved((t) => this._finishSubscription(t));
        await this._store.initialize([platform]);
        this._overrideItemPrices(this._store.products);
        return this._store.products.map(p => this._map.get(p.id));
    }

    async purchase(product: Product): Promise<boolean> {
        const sku = this.getProductSKU(product);
        if (this._orderObservers[sku]) return firstValueFrom(this._orderObservers[sku]);
        return this._zone.run(async () => {
            const iapProduct = this._store.get(sku);
            const offer = iapProduct.getOffer();
            if (!offer) return;

            const orderError = await offer.order();
            if (orderError) return false;
            this._orderObservers[iapProduct.id] = new Subject<boolean>();
            return firstValueFrom(this._orderObservers[sku]);
        });
    }

    async restore(): Promise<void> {
        return this._store.restorePurchases();
    }

    private _overrideItemPrices(iapProducts: CdvPurchase.Product[]) {
        iapProducts.forEach(item => this._overrideItemPrice(item));
    }

    private _overrideItemPrice(iapProduct: CdvPurchase.Product) {
        const product = this._map.get(iapProduct.id);
        if (!product || !iapProduct) return;
        if (!iapProduct?.pricing?.priceMicros) return;
        this.overridePrice(product, iapProduct);
    }

    private _registerSubscriptions(platform: CdvPurchase.Platform, products: Product[]) {
        const registeredSku: string[] = [];
        for (const product of products) {
            const sku = this.getProductSKU(product);
            if (!sku?.length) continue;
            if (registeredSku.includes(sku)) continue;
            registeredSku.push(sku);
            this._map.set(sku, product);
            this._store.register({ id: sku, platform: platform, type: CdvPurchase.ProductType.PAID_SUBSCRIPTION });
        }
    }
    private async _finishSubscription(transaction: CdvPurchase.Transaction) {
        try {
            await Promise.all(transaction.products.map(async product => {
                const iapProd = this._store.get(product.id);
                const prod = this._map.get(product.id);
                try {
                    if (this._lock[getId(prod)]) return;
                    const receipt = await this.getReceipt(transaction);
                    if (!isset(receipt)) return;
                    this._lock[getId(prod)] = true;
                    if (iapProd.type === CdvPurchase.ProductType.PAID_SUBSCRIPTION) await this.registerSubscription(prod, receipt);
                    this._lock[getId(product)] = false;

                    if (this._orderObservers[iapProd.id]) this._orderObservers[iapProd.id].next(true);
                } catch (err) {
                    this._lock[getId(product)] = false;
                    if (this._orderObservers[iapProd.id]) this._orderObservers[iapProd.id].next(false);
                    throw err;
                }
            }));
            transaction.finish();
        } catch (_e) { }
    }
}


export class PendingOrder<D> {
    static getDefault<D>(product: CdvPurchase.Product, item: D): PendingOrder<D> { return new PendingOrder(product, item); }
    private count: number = 0;
    private _decremented = new Subject<void>();

    constructor(
        public readonly product: CdvPurchase.Product,
        public readonly item: D
    ) { }

    public whenOrderCompleted(): Promise<void> { return lastValueFrom(this._decremented.pipe(take(1))); }

    public increment() { this.count++; }
    public decrement() {
        if (this.count > 0) {
            this.count--;
            this._decremented.next();
        }
    }

    public getItemIfPending() {
        if (this.count <= 0) return null;
        return this.item;
    }
}