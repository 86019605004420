import { Component, ContentChildren, Input, OnInit, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InputBoolean } from 'ng-zorro-antd/core/util';
import { WrapperContentDirective } from './wrapper-content.directive';
import { WrapperFooterDirective } from './wrapper-footer.directive';
import { WrapperHeaderDirective } from './wrapper-header.directive';
import { BooleanInput } from 'ng-zorro-antd/core/types';

@Component({
  selector: 'cwt-main-wrapper',
  templateUrl: './main-wrapper.component.html',
  styleUrls: ['./main-wrapper.component.less'],
  host: { 'class': 'ion-page' }
})
export class MainWrapperComponent implements OnInit {

  @Input() @InputBoolean() noBack: BooleanInput;
  @Input() @InputBoolean() gradient: BooleanInput;
  @Input() @InputBoolean() public: BooleanInput = false;
  @Input() color = 'gradient-gold';
  @Input() defaultHref: string = this.shouldReturnToPro() ? '/pro' : '/';
  @Input() overrideNav: () => boolean;
  @ContentChildren(WrapperContentDirective, { descendants: true }) public contentElements: QueryList<WrapperContentDirective>;
  @ContentChildren(WrapperFooterDirective, { descendants: true }) public footerElements: QueryList<WrapperFooterDirective>;
  @ContentChildren(WrapperHeaderDirective, { descendants: true }) public headerElements: QueryList<WrapperHeaderDirective>;

  public get path(): string { return this.router.url; }

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {

  }


  public shouldReturnToPro() {
    if (this.path.startsWith('/pros')) return false;
    if (this.path.startsWith('/pro/request')) return false;
    if (this.path.startsWith('/pro/')) return true;
    return false;
  }
}
