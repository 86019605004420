<cwt-modal-header-icon [icon]="icon"></cwt-modal-header-icon>

<ion-header>
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <ion-icon slot="icon-only" icon="close-circle-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<div class="inner-content">
    <ng-content></ng-content>
</div>