<ion-menu [side]="side"
    contentId="test"
    [menuId]="menuId">
    <ion-content color="gradient-light">
        <div class="menu-wrapper-content">
            <ng-content></ng-content>
        </div>
    </ion-content>
</ion-menu>

<!-- p'tit hack pour que le ion-menu fonctionne -->
<!-- sinon il arrive pu à récupérer le content défini -->
<!-- j'ai laissé l'integration existante au cas où ionic corrige ce bug à la con -->
<div style="display: none;" id="test"></div>