import { BooleanInput } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { InputBoolean } from 'ng-zorro-antd/core/util';

@Component({
  selector: 'cwt-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.less'],
  host: {
    '[class.section-title-with-border]': 'border',
    '[class.section-title-big]': 'big',
    '[class.section-title-inline]': 'inline',
    '[class.section-title-sans-serif]': 'sansSerif'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionTitleComponent implements OnInit {

  @Input() @InputBoolean() border: BooleanInput = false;
  @Input() @InputBoolean() big: BooleanInput = false;
  @Input() @InputBoolean() inline: BooleanInput = false;
  @Input() @InputBoolean() sansSerif: BooleanInput = false;

  constructor() { }

  ngOnInit(): void {
  }

}
