import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CwtCoreModule, CWT_INIT_SEQUENCE } from '@cawita/core-front';
import { AppUserModule } from '@shared/modules';
import { RecaptchaModule } from '@shared/widgets/features/common/recaptcha';
import { initGoogleMaps } from '@shared/services/apis';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { CwtUserCoreConfig } from './configs/core-front.config';
import { CwtUserIonicConfig } from './configs/ionic.config';
import { ActiveAddressModule } from './modules/address/active-address.module';
import { MobileCustomerModule } from './modules/customer';
import { CwtIonicModule } from './modules/ionic';
import { MobileProModule } from './modules/mobile-pro';
import { MediaViewerModule } from './widgets/features/common/media-viewer/media-viewer.module';
import { StrongAuthModalModule } from './widgets/smarts/auth/strong-auth-modal/strong-auth-modal.module';
import { RateProDialogModule } from './widgets/features/rate/rate-pro-dialog/rate-pro-dialog.module';
import { MarketModule } from './modules/market/market.module';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { NotificationModule } from './modules/notification/notification.module';
import { RateCustomerDialogModule } from './widgets/features/rate/rate-customer-dialog/rate-customer-dialog.module';
import { DeepLinkService, initializeDeepLink } from './services/deep-link.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RateProDialogModule,
    RateCustomerDialogModule,
    CwtIonicModule.forRoot(CwtUserIonicConfig),
    CwtCoreModule.forRoot(CwtUserCoreConfig),
    AppUserModule.forRoot(),
    MobileCustomerModule.forRoot(),
    NotificationModule.forRoot(),
    MobileProModule.forRoot(),
    ActiveAddressModule.forRoot(),
    MediaViewerModule.forRoot(),
    StrongAuthModalModule.forRoot(),
    MarketModule.forRoot(),
    RecaptchaModule.forRoot(environment.recaptchaSiteKey),
    RouterModule.forRoot([
      { path: 'auth', loadChildren: () => import('./pages/auth').then(m => m.AuthModule) },
      { path: '', loadChildren: () => import('./pages/main').then(m => m.MainModule) },
      { path: '**', pathMatch: 'full', redirectTo: '/' }
    ], {

    })
  ],
  providers: [
    InAppPurchase2,
    { provide: APP_INITIALIZER, useFactory: initGoogleMaps(environment.googlePlaceApiKey), multi: true },
    { provide: APP_INITIALIZER, deps: [DeepLinkService], useFactory: initializeDeepLink, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
