import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CWT_AUTH_LISTENER } from '@cawita/core-front/auth';
import { SharedProModule } from '@shared/modules/shared-pro';
import { ActiveProDirective } from './active-pro.directive';
import { CurrentSubscriptionDirective } from './current-subscription.directive';
import { ProConfigStore } from './pro-config.store';
import { ProStore } from './pro.store';
import { MARKET_LISTENER } from '../market/providers/market-listener.provider';
import { CanEditProDirective } from './can-edit-pro.directive';



@NgModule({
  declarations: [
    ActiveProDirective,
    CurrentSubscriptionDirective,
    CanEditProDirective
  ],
  imports: [
    CommonModule,
    SharedProModule,
  ],
  exports: [
    SharedProModule,
    ActiveProDirective,
    CurrentSubscriptionDirective,
    CanEditProDirective
  ]
})
export class MobileProModule {
  static forRoot(): ModuleWithProviders<MobileProModule> {
    return {
      ngModule: MobileProModule,
      providers: [
        { provide: CWT_AUTH_LISTENER, useExisting: ProStore, multi: true },
        { provide: CWT_AUTH_LISTENER, useExisting: ProConfigStore, multi: true },
        { provide: MARKET_LISTENER, useExisting: ProConfigStore, multi: true }
      ]
    }
  }
}