
<ng-container *cwtIsLogged="let logged">
    <ion-header>
        <ion-toolbar [color]="gradient ? 'transparent' : color">
            <ion-buttons *ngIf="!noBack" slot="start">
                <ion-button slot="start" cwtBackButton [defaultHref]="defaultHref" [overrideNav]="overrideNav">
                    <ion-icon slot="icon-only" icon="arrow-back-outline"></ion-icon>
                </ion-button>
            </ion-buttons>
            <ng-container *ngIf="headerElements?.length; else headerLogo">
                <ng-content select="[cwtWrapperHeader]"></ng-content>
            </ng-container>

            <ng-template #headerLogo>
                <div class="logo-img">
                    <img class="w-100" src="assets/img/logo_text.png" height="40">
                </div>
            </ng-template>
        </ion-toolbar>
    </ion-header>

    <ion-content *ngIf="public || logged; else loggedOutView" [fullscreen]="gradient" [color]="gradient ? color : undefined">
        <ng-container *ngFor="let content of contentElements">
            <ng-container *ngTemplateOutlet="content.ref"></ng-container>
        </ng-container>
    </ion-content>

    <ng-template #loggedOutView>
        <ion-content>
            <cwt-centered-page slot="fixed" align="center" justify="center">
                <h4 class="mb-3 text-center"><ion-text color="dark">{{'alert.login-to-access-content' | translate}}</ion-text></h4>
                <ion-button color="dark" class="mt-3" [routerLink]="['/auth', 'login']" [queryParams]="{ redirect: path }">
                    <ion-text>{{'action.login' | translate}}</ion-text>
                </ion-button>
            </cwt-centered-page>
        </ion-content>
    </ng-template>

    <ion-footer *ngIf="footerElements?.length">
        <ion-toolbar [color]="gradient ? 'transparent' : 'bg'">
            <ng-container *ngFor="let footer of footerElements">
                <ng-container *ngTemplateOutlet="footer.ref"></ng-container>
            </ng-container>
        </ion-toolbar>
    </ion-footer>
</ng-container>