import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { ProStore } from './pro.store';

@Injectable({ providedIn: 'root' })
export class ProSetupedGuard implements CanActivate {
    constructor(private pro: ProStore) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return true;
    }
}