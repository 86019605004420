import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StrongAuthModalComponent } from './strong-auth-modal.component';
import { CWT_STRONG_AUTH_HANDLER } from '@cawita/core-front/auth';
import { StrongAuthService } from './strong-auth-service';
import { CwtIonicModule } from '../../../../modules/ionic';
import { MobileLayoutModule } from '../../../features/layout/layout.module';
import { AppFormModule } from '@shared/modules';



@NgModule({
  declarations: [
    StrongAuthModalComponent
  ],
  imports: [
    CommonModule,
    CwtIonicModule,
    AppFormModule,
    MobileLayoutModule
  ],
  exports: [
    StrongAuthModalComponent
  ]
})
export class StrongAuthModalModule {
  static forRoot(): ModuleWithProviders<StrongAuthModalModule> {
    return {
      ngModule: StrongAuthModalModule,
      providers: [
        StrongAuthService,
        { provide: CWT_STRONG_AUTH_HANDLER, useExisting: StrongAuthService }
      ]
    }
  }
}
