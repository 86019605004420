import { Directive, HostListener, Input, Optional } from '@angular/core';
import { IonRouterOutlet, NavController } from '@ionic/angular';
import { AnimationBuilder } from '@ionic/core';

@Directive({
  selector: '[cwtBackButton]'
})
export class BackButtonDirective {

  @Input() defaultHref: string;

  /**
   * Should return true to cancel back event.
   */
  @Input() overrideNav: () => boolean;

  @Input() routerAnimation?: AnimationBuilder;


  constructor(
    @Optional() private routerOutlet: IonRouterOutlet,
    private navCtrl: NavController
  ) { }

  // GROS CACA-COLLE DE IONIC WOWOWOWO
  @HostListener('click', ['$event'])
  onClick(ev: Event): void {
    const overrideNav = this.overrideNav?.();
    if (overrideNav) return;

    if (this.routerOutlet?.canGoBack()) {
      this.navCtrl.setDirection('back', undefined, undefined, this.routerAnimation);
      this.routerOutlet.pop();
      ev.preventDefault();
    } else if (this.defaultHref != null) {
      this.navCtrl.navigateBack(this.defaultHref, { animation: this.routerAnimation });
      ev.preventDefault();
    }
  }

}
