import { Directive, HostListener, Input } from '@angular/core';
import { MenuController } from '@ionic/angular';

@Directive({
  selector: '[cwtToggleMenu]'
})
export class ToggleMenuDirective {

  @Input('cwtToggleMenu') menuId: string;

  constructor(private menu: MenuController) { }

  @HostListener('click', ['$event'])
  public async onClick($event: Event) {
    if (!this.menuId) return;

    $event?.preventDefault();
    $event?.stopPropagation();
    const isEnabled = await this.menu.isEnabled(this.menuId)
    if (!isEnabled) await this.menu.enable(true, this.menuId);
    await this.menu.toggle(this.menuId);
  }
}
