import { Injectable } from '@angular/core';
import { CwtStrongAuthResponse, CwtStrongAuthStatus, ICwtStrongAuthHandler } from '@cawita/core-front/auth';
import { ModalController } from '@ionic/angular';
import { StrongAuthModalComponent } from './strong-auth-modal.component';

@Injectable({ providedIn: 'root' })
export class StrongAuthService implements ICwtStrongAuthHandler {
    constructor(private modal: ModalController) { }

    async request(): Promise<CwtStrongAuthResponse> {
        const modal = await this.modal.create({
            component: StrongAuthModalComponent,
            backdropDismiss: false,
            cssClass: ['cwt-dynamic-height-modal']
        });

        await modal.present();

        const res = await modal.onWillDismiss();
        return res.data;
    }


}