import { coerceArray } from "@angular/cdk/coercion";
import { isset } from "@cawita/core-front";
import { Pro, ProAcceptedFlows, ProStatus } from "@shared/models";

export function isInfoStepComplete(pro: Pro) {
    if (!isset(pro)) return false;
    if (!isset(pro?.companyName?.length)) return false;
    if (!isset(pro?.workarea)) return false;
    if (!isset(pro?.radius)) return false;
    if (!isset(pro?.experience)) return false;
    return true;
}

export function isActivityStepComplete(pro: Pro) {
    if (!isset(pro)) return false;
    if (!isset(pro.jobs) || !pro?.jobs?.length) return false;
    return true;
}

export function isAvailabilityStepComplete(pro: Pro) {
    if (!isset(pro)) return false;
    return true;
}

export function isRequestComplete(pro: Pro) {
    if (!isset(pro)) return false;
    if (pro.adminStatus !== ProStatus.Validated) return false;
    return true;
}

export function proHasStatus(pro: Pro, status: ProStatus | ProStatus[]) {
    if (!isset(pro)) return false;
    return coerceArray(status).includes(pro.adminStatus);
}

export function proAllowsFlow(pro: Pro, flow: (keyof ProAcceptedFlows) | (keyof ProAcceptedFlows)[]) {
    if (!isset(pro)) return false;
    return coerceArray(flow).some(flow => !!pro?.acceptedFlows[flow]);
}

export function isSetupComplete(pro: Pro) {
    return isRequestComplete(pro) &&
        isInfoStepComplete(pro) &&
        isActivityStepComplete(pro) &&
        isAvailabilityStepComplete(pro);
}