import { Inject, Injectable } from '@angular/core';
import { Customer, Notification, Pro } from '@shared/models';
import { SimpleNotificationContract, SimpleNotificationService } from '@shared/services/apis';
import { ICustomerListener } from '../customer/customer-listener';
import { IProListener } from '../mobile-pro/pro-listener';
import { PushNotificationService } from './push-notification.service';

@Injectable({ providedIn: 'root' })
export class NotificationInitService implements ICustomerListener, IProListener {
    constructor(
        private push: PushNotificationService,
        @Inject(SimpleNotificationContract.get(Notification, 'customer')) private customerNotif: SimpleNotificationService<Notification>,
        @Inject(SimpleNotificationContract.get(Notification, 'pro')) private proNotif: SimpleNotificationService<Notification>,
    ) { }

    customerInitialized(customer?: Customer): void {
        if (!customer) this.customerNotif.clear();
        else {
            this.push.init();
            this.customerNotif.init().subscribe();
        }
    }

    proInitialized(pro?: Pro): void {
        if (!pro) this.proNotif.clear();
        else this.proNotif.init().subscribe();
    }

}