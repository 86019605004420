import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { MarketStore } from '../market.store';
import { Subscription } from '@shared/models';

@Directive({
  selector: '[cwtRestorePurchase]'
})
export class RestorePurchaseDirective implements OnInit {

  constructor(private store: MarketStore) { }

  ngOnInit(): void {
    this.store.init().subscribe();
  }


  @HostListener('click')
  public manageSubscription() {
    this.store.restore();
  }

}
