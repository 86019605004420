import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CwtI18nService, CwtTranslatable, isset, translateDictionary } from '@cawita/core-front/common';
import { ToastController } from '@ionic/angular';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CwtIonMessageService {
    constructor(
        private i18n: CwtI18nService,
        private ionToast: ToastController
    ) { }

    public error(content: CwtTranslatable) { return this._open('danger', content); }
    public success(content: CwtTranslatable) { return this._open('success', content); }
    public warning(content: CwtTranslatable) { return this._open('warning', content); }
    public httpError(httpError: HttpErrorResponse) {
        const messageTranslated = translateDictionary(httpError?.error?.translations, this.i18n.currentLocale, null, this.i18n.defaultLocale);
        if (isset(messageTranslated?.length)) return this._open('danger', messageTranslated);
        const error = httpError?.error?.code ?? httpError?.error?.msg ?? httpError?.status;
        return this._open('danger', { key: `errors.${error}`, params: httpError?.error });
    }

    private async _open(type: string, content: CwtTranslatable) {
        const contentTranslated = await lastValueFrom(this.i18n.get(content));
        const toast = await this.ionToast.create({
            color: type,
            message: contentTranslated,
            position: 'bottom',
            duration: 3000,
            translucent: false
        });
        await toast.present();
    }
}