
import { Injectable } from '@angular/core';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { Browser } from '@capacitor/browser';
import { CwtApiService } from '@cawita/core-front/api';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MarketManageSubscriptionService {
    constructor(
        private api: CwtApiService,
        private _iap: InAppPurchase2) { }

    public async manageStripe() {
        const { url } = await lastValueFrom(this.api.post<any>(`as-pro/mine/stripe/portal`));
        await Browser.open({ windowName: '_self', url: url });
    }

    public async manageApple() {
        await Browser.open({ windowName: '_self', url: 'https://finance-app.itunes.apple.com/account/subscriptions' });
    }

    public async manageGoogle() {
        await Browser.open({ windowName: '_self', url: `https://play.google.com/store/account/subscriptions` })
    }

    public async manageImplicitIAP() {
        this._iap.manageSubscriptions();
    }
}