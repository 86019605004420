import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';

export function initializeDeepLink(svc: DeepLinkService) {
    return () => svc.init();
}

@Injectable({ providedIn: 'root' })
export class DeepLinkService {
    constructor(
        private router: Router,
        private zone: NgZone
    ) { }

    public async init() {
        const { platform } = await Device.getInfo();
        if (platform === 'web') return;
        const launchUrl = await App.getLaunchUrl();
        if (launchUrl?.url) this.zone.run(() => this._handleURL(launchUrl.url));
        await App.addListener('appUrlOpen', (event) => {
            this.zone.run(() => this._handleURL(event.url));
        });
    }

    private _handleURL(urlString: string) {
        const url = this._parseURL(urlString);
        if (!url) return;

        const pathWithoutDomain = url.href.replace(`${url.protocol}//${url.host}`, '')
        this.zone.run(() => {
            Browser.close();
            this.router.navigateByUrl(pathWithoutDomain, { replaceUrl: true })
        });
    }

    private _parseURL(urlString: string) {
        try {
            return new URL(urlString);
        } catch (_e) {
            return null;
        }
    }
}