import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CwtMediaType } from '@shared/modules/media/media.utils';

@Component({
  templateUrl: './media-viewer.component.html',
  styleUrls: ['./media-viewer.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaViewerComponent implements OnInit {
  public mediaType: CwtMediaType;
  public src: string | File;
  public name: string;
  public onDelete: () => void = null;

  constructor(private modal: ModalController) { }

  ngOnInit(): void {
  }

  close() {
    this.modal.dismiss();
  }
}
