import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICwtApiErrorHandler } from '@cawita/core-front/api';
import { CwtIonMessageService } from './message.service';

@Injectable({ providedIn: 'root' })
export class CwtIonErrorHandlerService implements ICwtApiErrorHandler {

    constructor(private message: CwtIonMessageService) { }

    handle(error: HttpErrorResponse): void {
        this.message.httpError(error);
    }

}