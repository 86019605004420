import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView } from '@cawita/core-front';
import { MarketProvider } from '@shared/models';
import { MarketStore } from '../market.store';

export type MarketProviderContext = {
  $implicit: MarketProvider;
}

@Directive({
  selector: '[cwtMarketProvider]'
})
export class MarketProviderDirective implements OnInit {
  private _ctx: MarketProviderContext = { $implicit: null };
  private _view = new CwtDirectiveView(this.vcRef, this.tRef, this._ctx);

  constructor(
    private vcRef: ViewContainerRef,
    private tRef: TemplateRef<MarketProviderContext>,
    private store: MarketStore
  ) { }

  ngOnInit(): void {
    this._ctx.$implicit = this.store.getProvider();
    this._view.ensureState(true);
  }

}
