import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProHasStatusDirective } from './pro-has-status.directive';
import { ProAllowsFlowDirective } from './pro-allows-flow.directive';



@NgModule({
  declarations: [
    ProHasStatusDirective,
    ProAllowsFlowDirective
  ],
  exports: [
    ProHasStatusDirective,
    ProAllowsFlowDirective
  ],
  imports: [
    CommonModule
  ]
})
export class SharedProModule { }
