import { Injectable } from '@angular/core';
import { MarketProvider, Product } from '@shared/models';
import { IMarketAdapter } from './market.adapter';

@Injectable({ providedIn: 'root' })
export class NoopMarketService implements IMarketAdapter {
    constructor() { }


    getProvider(): MarketProvider {
        return null;
    }
    async getProducts(): Promise<Product[]> { return []; }
    async initialize(products: Product[]): Promise<void> { }
    async purchase(product: Product): Promise<boolean> { return false; }
    async manageSubscription(): Promise<void> { }
    async restore(): Promise<void> { return; }
}