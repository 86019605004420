import { InjectionToken } from "@angular/core";
import { MarketProvider, Product, Subscription } from "@shared/models";

export const MARKET_ADAPTER = new InjectionToken<IMarketAdapter>('cwt-market-adapter');

export interface IMarketAdapter {
    getProvider(): MarketProvider;
    getProducts(): Promise<Product[]>;
    initialize(products: Product[]): Promise<void | Product[]>;
    purchase(product: Product): Promise<boolean>;
    manageSubscription(sub: Subscription): Promise<void>;
    restore(): Promise<void>;
}