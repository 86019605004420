import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { InputBoolean } from 'ng-zorro-antd/core/util';

@Component({
  selector: 'cwt-card-with-image',
  templateUrl: './card-with-image.component.html',
  styleUrls: ['./card-with-image.component.less'],
  host: { '[class.ion-activatable]': 'ripple' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardWithImageComponent {

  @Input() image: string;
  @Input() title: string;
  @Input() color: string;
  @HostBinding('style.--cwt-card-width') @Input() width: string;

  @Input() @InputBoolean() ripple = true;

  constructor() { }

}
