
import { Injectable } from '@angular/core';
import { CwtApiService, CwtRequestConfig } from '@cawita/core-front/api';

@Injectable({ providedIn: 'root' })
export class CustomerApi {
    constructor(private api: CwtApiService) { }

    deleteCustomer(credentials:any , config?:CwtRequestConfig){
        return this.api.delete(`/as-customer/customer/mine`, {strongAuthenticationCredentials:credentials} , config);
    }
    
}

