import { isRequestComplete, isSetupComplete, isSubscribed } from '@shared/modules/shared-pro';
import { RunCanActivateInSequence } from '@shared/services/sequence.guard';
import { CwtStoreCheckGuard } from '@shared/services/store-check.guard';
import { CwtStoreInitializedGuard } from '@shared/services/store-initialized.guard';
import { ProConfigStore } from './pro-config.store';
import { ProStore } from './pro.store';

export function ProAccessGuard() {
    return RunCanActivateInSequence([
        CwtStoreInitializedGuard(ProStore),
        CwtStoreCheckGuard(ProStore, (state, router) => {
            if (!isRequestComplete(state.pro)) return router.createUrlTree(['/pro', 'request']);
            else if (!isSetupComplete(state.pro)) return router.createUrlTree(['/pro', 'setup']);
            else return true;
        })
    ]);
}

export function ProRequestAccessGuard() {
    return RunCanActivateInSequence([
        CwtStoreInitializedGuard(ProStore),
        CwtStoreCheckGuard(ProStore, (state, router) => {
            if (!isRequestComplete(state.pro)) return true;
            else if (!isSetupComplete(state.pro)) return router.createUrlTree(['/pro', 'setup']);
            else return router.createUrlTree(['/pro']);
        })
    ]);
}

export function ProSetupAccessGuard() {
    return RunCanActivateInSequence([
        CwtStoreInitializedGuard(ProStore),
        CwtStoreCheckGuard(ProStore, (state, router) => {
            if (!isRequestComplete(state.pro)) return router.createUrlTree(['/pro', 'request']);
            else if (!isSetupComplete(state.pro)) return true;
            else return router.createUrlTree(['/pro']);
        }),
    ]);
}

export function ProSubscribeGuard() {
    return RunCanActivateInSequence([
        CwtStoreInitializedGuard(ProConfigStore),
        CwtStoreCheckGuard(ProConfigStore, (state, router) => {
            if (!isSubscribed(state?.subscription)) return router.createUrlTree(['/pro', 'subscribe']);
            return true;
        })
    ]);
}

export function ProNotSubscribedGuard() {
    return RunCanActivateInSequence([
        CwtStoreInitializedGuard(ProConfigStore),
        CwtStoreCheckGuard(ProConfigStore, (state, router) => {
            if (isSubscribed(state?.subscription)) return router.createUrlTree(['/pro', 'setting', 'subscription']);
            return true;
        })
    ]);
}