import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CUSTOMER_LISTENER } from '../customer/customer-listener';
import { NotificationInitService } from './notification-init.service';
import { PRO_LISTENER } from '../mobile-pro/pro-listener';
import { SOCKET_URL } from '@shared/services/apis';
import { environment } from '../../../environments/environment';
import { UnreadNotificationDirective } from './unread-notification.directive';



@NgModule({
  declarations: [
    UnreadNotificationDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    UnreadNotificationDirective
  ]
})
export class NotificationModule {
  static forRoot(): ModuleWithProviders<NotificationModule> {
    return {
      ngModule: NotificationModule,
      providers: [
        { provide: SOCKET_URL, useValue: environment.socketUrl },
        { provide: CUSTOMER_LISTENER, useExisting: NotificationInitService, multi: true },
        { provide: PRO_LISTENER, useExisting: NotificationInitService, multi: true },
      ]
    }
  }
}
