import { Inject, Injectable, NgZone } from '@angular/core';
import { CwtStore } from '@cawita/core-front/state';
import { EMPTY, from, map, switchMap, tap } from 'rxjs';
import { MarketProvider, Product, Subscription } from '../../../../../frontend-shared/src/lib/models';
import { IMarketAdapter, MARKET_ADAPTER } from './markets/market.adapter';
import { IMarketListener, MARKET_LISTENER } from './providers/market-listener.provider';

export type MarketState = {
    products: Product[];
    perType: Record<string, Product[]>,
}

@Injectable({ providedIn: 'root' })
export class MarketStore extends CwtStore<MarketState> {
    private _initRequested = false;

    constructor(
        @Inject(MARKET_ADAPTER) private adapter: IMarketAdapter,
        @Inject(MARKET_LISTENER) private listeners: IMarketListener[],
        private zone: NgZone
    ) {
        super({
            initialized: false,
            loading: false,
            products: [],
            perType: {}
        });
    }

    public init() {
        if (this._initRequested) return EMPTY;
        this._initRequested = true;
        this.setInitialized(false);
        return from(this._initAdapter()).pipe(
            tap((products) => this.setState(c => ({
                ...c,
                initialized: true,
                loading: false,
                products: products,
                perType: products.reduce((map, product) => {
                    if (!map[product.type]) map[product.type] = [product];
                    else map[product.type].push(product);
                    return map;
                }, {})
            })))
        );
    }

    public getProvider(): MarketProvider {
        return this.adapter.getProvider();
    }

    public purchase(product: Product) {
        return from(this.adapter.purchase(product)).pipe(
            switchMap(res => {
                if (res) return from(this._triggerPurchaseComplete(product)).pipe(map(() => true));
                else return from(this._triggerPurchaseFailed(product)).pipe(map(() => false));
            })
        );
    }

    private async _triggerPurchaseComplete(product: Product) {
        for (const listener of this.listeners ?? []) {
            await listener?.onPurchaseComplete?.(product)?.catch(() => { });
        }
    }

    private async _triggerPurchaseRefreshed() {
        for (const listener of this.listeners ?? []) {
            await listener?.onPurchaseRefreshed?.()?.catch(() => { });
        }
    }

    private async _triggerPurchaseFailed(product: Product) {
        for (const listener of this.listeners ?? []) {
            await listener?.onPurchaseFailed?.(product)?.catch(() => { });
        }
    }

    public manage(sub: Subscription) {
        this.adapter.manageSubscription(sub);
    }

    public restore() {
        from(this.adapter.restore()).subscribe(() => {
            this._triggerPurchaseRefreshed();
        });
    }

    private async _initAdapter() {
        const products = await this.adapter.getProducts();
        const res = await this.adapter.initialize(products);
        if (Array.isArray(res)) return res;
        return products;
    }
}