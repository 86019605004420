import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CWT_I18N_LISTENER } from '@cawita/core-front';
import { CWT_API_CONNECTION_HANDLER, CWT_API_ERROR_HANDLER } from '@cawita/core-front/api';
import { CwtNzI18nListener } from '@cawita/core-front/zorro';
import { IonicModule } from '@ionic/angular';
import { IonicConfig } from '@ionic/core';
import { NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { fr_FR, NZ_I18N } from 'ng-zorro-antd/i18n';
import { AppCommonModule } from '@shared/modules';
import { CwtIonConnectionHandlerService } from './connection-handler.service';
import { CwtIonErrorHandlerService } from './error-handler.service';
import { IsPlatformDirective } from './is-platform.directive';
import { LogoutDirective } from './logout.directive';
import { SelectLocaleDirective } from './select-locale.directive';
import { ToggleMenuDirective } from './toggle-menu.directive';
import { PaginatedStoreInfiniteScrollDirective } from './paginated-store-infinite-scroll.directive';
import { BackButtonDirective } from './back-button.directive';


@NgModule({
  declarations: [
    ToggleMenuDirective,
    IsPlatformDirective,
    LogoutDirective,
    SelectLocaleDirective,
    PaginatedStoreInfiniteScrollDirective,
    BackButtonDirective
  ],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [
    CommonModule,
    AppCommonModule,
    IonicModule,
    RouterModule,
    ToggleMenuDirective,
    IsPlatformDirective,
    SelectLocaleDirective,
    LogoutDirective,
    PaginatedStoreInfiniteScrollDirective,
    BackButtonDirective
  ]
})
export class CwtIonicModule {
  static forRoot(config?: IonicConfig): ModuleWithProviders<CwtIonicModule> {
    return {
      ngModule: CwtIonicModule,
      providers: [
        ...IonicModule.forRoot(config).providers,
        { provide: NZ_I18N, useValue: fr_FR, },
        { provide: NZ_CONFIG, useValue: config ?? {} },
        { provide: CWT_I18N_LISTENER, useClass: CwtNzI18nListener, multi: true },
        { provide: CWT_API_ERROR_HANDLER, useExisting: CwtIonErrorHandlerService, multi: true },
        { provide: CWT_API_CONNECTION_HANDLER, useExisting: CwtIonConnectionHandlerService }
      ]
    }
  }
}
