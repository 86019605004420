import { Directive, EventEmitter, HostListener, Input, NgZone, OnInit, Output } from '@angular/core';
import { Product } from '@shared/models';
import { MarketStore } from '../market.store';

@Directive({
  selector: '[cwtPurchase]'
})
export class PurchaseDirective implements OnInit {

  @Input('cwtPurchase') prod: Product;
  @Output() purchaseSuccess = new EventEmitter<void>();
  @Output() purchaseFail = new EventEmitter<void>();

  constructor(private zone: NgZone, private store: MarketStore) { }

  ngOnInit(): void {
    this.store.init().subscribe();
  }

  @HostListener('click')
  public purchase() {
    if (!this.prod) return;
    this.store.purchase(this.prod).subscribe((res) => this.zone.run(() => {
      console.log('BEFORE EMIT');
      if (res) this.purchaseSuccess.emit();
      else this.purchaseFail.emit();
    }));
  }

}
