import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, hasSomeChanges } from '@cawita/core-front';
import { Pro, ProStatus } from '@shared/models';
import { Subscription } from 'rxjs';
import { proHasStatus } from './shared-pro.utils';

@Directive({ selector: '[cwtProHasStatus]' })
export class ProHasStatusDirective implements OnChanges {
    private _view = new CwtDirectiveView(this.vcRef, this.tRef, {});
    private _elseView = new CwtDirectiveView(this.vcRef, null, {});
    private _sub: Subscription;

    @Input('cwtProHasStatus') pro: Pro;
    @Input('cwtProHasStatusStatus') status: ProStatus | ProStatus[];
    @Input('cwtProHasStatusElse') set elseTemplate(v: TemplateRef<any>) {
        this._elseView.setTemplate(v);
    }

    constructor(
        private vcRef: ViewContainerRef,
        private tRef: TemplateRef<any>
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSomeChanges(changes, ['pro', 'status'], true)) this._update();
    }

    ngOnDestroy(): void {
        this._sub?.unsubscribe();
    }

    private _update() {
        const matchStatus = proHasStatus(this.pro, this.status);
        this._view.ensureState(matchStatus);
        this._elseView.ensureState(!matchStatus);
    }
}