import { Injectable } from '@angular/core';
import { isset } from '@cawita/core-front';
import { LocalizedAddress } from '@shared/models/localized-address.model';
import { LocalStoragePersistencyService } from '../../services/locale-storage-persistency.service';




@Injectable({ providedIn: 'root' })
export class ActiveAddressPersistencyService {

    constructor( private service:LocalStoragePersistencyService
    ) { }

    set(storeName: string, options: LocalizedAddress) {
        if (!options) return;
        this.service.set(storeName, options);
    }

    get(storeName: string): LocalizedAddress {
        const { address, coord, isGeoloc } = this.service.get(storeName) ?? {};
        return  (isset(address) || isset(coord) || isset(isGeoloc)) ? new LocalizedAddress({ address, coord, isGeoloc }) : null;
    }
}
