import { ChangeDetectorRef, Directive, Host, Input, OnDestroy, OnInit } from '@angular/core';
import { continueStreamOnError } from '@cawita/core-front';
import { CwtPaginatedStore } from '@cawita/core-front/state';
import { IonInfiniteScroll } from '@ionic/angular';
import { finalize, map, Observable, Subscription, switchMap, tap } from 'rxjs';
import { SimpleNotificationService } from '../../../../../frontend-shared/src/lib/services/apis';

@Directive({
  selector: 'ion-infinite-scroll[cwtPaginatedStoreInfiniteScroll]'
})
export class PaginatedStoreInfiniteScrollDirective<D> implements OnInit, OnDestroy {

  private _sub: Subscription;
  private _storeSub: Subscription;
  @Input('cwtPaginatedStoreInfiniteScroll') store: CwtPaginatedStore<D> | SimpleNotificationService<D>;

  constructor(
    @Host() private loading: IonInfiniteScroll,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this._updateLoaderState();
    this._setupLoader();
  }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
    this._storeSub?.unsubscribe();
  }

  private _setupLoader() {
    this.loading.position = 'bottom';
    this.loading.threshold = '100px';
    this._storeSub = (this.store.state$ as Observable<any>).subscribe(() => this._updateLoaderState());

    this._sub = this.loading.ionInfinite.pipe(
      switchMap(() => (this.store.nextPage() as Observable<any>).pipe(
        continueStreamOnError(),
        finalize(() => this.loading.complete())
      )),
    ).subscribe((disabled) => {
      this._updateLoaderState();
    })
  }

  private _updateLoaderState() {
    const { items, total } = this.store.getValue();
    this.loading.disabled = items?.length >= total;
    this.cdRef.markForCheck();
  }

}
