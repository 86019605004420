import { Inject, Injectable } from '@angular/core';
import { CwtI18nService, getId } from '@cawita/core-front';
import { CwtCrudContract, CwtCrudService } from '@cawita/core-front/api';
import { ICwtAuthListener } from '@cawita/core-front/auth';
import { CwtStore } from '@cawita/core-front/state';
import { Customer } from '@shared/models';
import { EMPTY, finalize, tap } from 'rxjs';
import { CUSTOMER_LISTENER, ICustomerListener } from './customer-listener';
import { CustomerApi } from '@shared/services/apis/customer.api';

export type CustomerState = {
    customer: Customer;
}

@Injectable({ providedIn: 'root' })
export class CustomerStore extends CwtStore<CustomerState> implements ICwtAuthListener {
    constructor(
        @Inject(CwtCrudContract.get(Customer, 'as-customer')) protected customerCrud: CwtCrudService<Customer>,
        @Inject(CUSTOMER_LISTENER) protected listeners: ICustomerListener[],
        private customerAPI:CustomerApi,
        private i18n: CwtI18nService
    ) {
        super({
            initialized: false,
            loading: false,
            customer: null
        });
    }

    onLogin(user: any): void {
        this.init().subscribe(customer => {
            if (!customer?.lang) this.setLocale(this.i18n.currentLocale);
            else this.i18n.use(customer.lang.toLowerCase()).subscribe();
            this.listeners?.forEach(l => l.customerInitialized(customer));
        });
    }

    onLogout(): void {
        this.clear();
        this.listeners?.forEach(l => l.customerInitialized(null));
    }

    public init() {
        this.setInitialized(false);
        return this.customerCrud.getById('mine', {
            options: { projection: 'forUserCustomerDetails' },
        }).pipe(
            finalize(() => this.setInitialized(true)),
            tap(customer => this.replace(customer))
        );
    }

    public clear() {
        this.setState({ customer: null, initialized: false, loading: false });
    }

    public replace(customer: Customer) {
        this.setState(c => ({
            ...c,
            customer
        }));
    }

    public delete(credentials) {
        const { customer } = this.getValue();
        if (!customer) return EMPTY;
        return this.customerAPI.deleteCustomer(credentials).pipe(
            tap(() => this.clear())
        );
    }

    public update(data: any) {
        const { customer } = this.getValue();
        if (!customer) return EMPTY;
        return this.customerCrud.update(getId(customer), data).pipe(
            tap(customer => this.replace(customer))
        );
    }

    public setLocale(locale: string) {
        return this.update({ lang: locale?.toUpperCase() || 'FR' }).subscribe();
    }

}