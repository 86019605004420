import { Component, Input } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { CustomerRate } from "@shared/models";

@Component({
  templateUrl: './rate-customer-dialog.component.html',
  styleUrls: ['./rate-customer-dialog.component.less'],
})
export class RateCustomerDialogComponent {
  @Input() currentRate:CustomerRate;
  @Input() form: FormGroup = new FormGroup({
    rate: new FormGroup({
      punctuality: new FormControl<number | null>(5),
      communication: new FormControl<number | null>(5),
      accuracy: new FormControl<number | null>(5),
      comment: new FormControl<string | null>(null)
    })
  });

  constructor(public modal: ModalController) { }

  ngOnInit(): void {
    if(this.currentRate) this.form.patchValue({ rate:this.currentRate })
   }
}