import { inject, Type } from "@angular/core";
import { CanActivateFn, Router, UrlTree } from "@angular/router";
import { CwtStore } from "@cawita/core-front/state";
import { filter, map, take } from "rxjs";

export function CwtStoreCheckGuard<S>(store: Type<CwtStore<S>>, check: (state: S, router: Router) => boolean | UrlTree): CanActivateFn {
    return (route, state) => {
        const storeV = inject(store);
        const router = inject(Router);
        return storeV.state$.pipe(
            filter(state => !!state.initialized),
            take(1),
            map((state) => check(state, router))
        );
    }
}