import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[cwtWrapperFooter]'
})
export class WrapperFooterDirective {

  constructor(public ref: TemplateRef<any>) { }

}
