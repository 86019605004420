import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cwt-grid-list',
  templateUrl: './grid-list.component.html',
  styleUrls: ['./grid-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridListComponent implements OnInit {

  @Input() gap = 2;

  constructor() { }

  ngOnInit(): void {
  }

}
