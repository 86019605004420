import { Injectable } from '@angular/core';
import { CwtStorageService } from '@cawita/core-front';


@Injectable({ providedIn: 'root' })
export class LocalStoragePersistencyService {

    constructor(private storageService:CwtStorageService) { }

    set(storeName: string, options: any) {
        this.storageService.setObject(storeName, options);
    }

    get(storeName: string):any {
        return this.storageService.getObject(storeName) || {};
    }
}
