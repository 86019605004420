import { BooleanInput } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InputBoolean } from 'ng-zorro-antd/core/util';



@Component({
  selector: 'cwt-card-with-icon',
  templateUrl: './card-with-icon.component.html',
  styleUrls: ['./card-with-icon.component.less'],
  host: {
    '[class.ion-activatable]': 'ripple',
    '[class.with-border]': 'border',
    '[class.with-shadow]': 'shadow'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardWithIconComponent {


  @Input() link: string[]
  @Input() icon: string;
  @Input() buttonColor: string = "light";
  @Input() iconColor: string = "black"
  @Input() @InputBoolean() ripple: BooleanInput = false;
  @Input() @InputBoolean() column: BooleanInput = false;
  @Input() @InputBoolean() border: BooleanInput = false;
  @Input() @InputBoolean() shadow: BooleanInput = false;


  constructor(
  ) { }

}
