<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button (click)="close()">
                <ion-icon slot="icon-only" icon="close"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-buttons *ngIf="onDelete" slot="end">
            <ion-button (click)="onDelete()" (click)="close()" color="danger">
                <ion-icon slot="icon-only" icon="trash"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>


<ion-content>
    <div slot="fixed" class="w-100 h-100" [ngSwitch]="mediaType">
        <cwt-image *ngSwitchCase="'image'" [src]="src" width="100%" height="100%" fitting="contain">
            <cwt-loading-addon *cwtMediaAddon="'loading'"></cwt-loading-addon>
            <cwt-icon-addon *cwtMediaAddon="'placeholder'" icon="picture"></cwt-icon-addon>
            <cwt-icon-addon *cwtMediaAddon="'error'" icon="exclamation-circle"></cwt-icon-addon>
        </cwt-image>
        <cwt-video *ngSwitchCase="'video'" [controls]="true" [src]="src" width="100%" height="100%" fitting="contain">
            <cwt-loading-addon *cwtMediaAddon="'loading'"></cwt-loading-addon>
            <cwt-icon-addon *cwtMediaAddon="'placeholder'" icon="video-camera"></cwt-icon-addon>
            <cwt-icon-addon *cwtMediaAddon="'error'" icon="exclamation-circle"></cwt-icon-addon>
        </cwt-video>
    </div>
</ion-content>