import { EnvironmentInjector, inject, Injector, Type, ɵisInjectable as isInjectable } from "@angular/core";
import { CanActivate, CanActivateFn, UrlTree } from "@angular/router";
import { isFunction } from "@cawita/core-front";
import { wrapIntoObservable } from "ng-zorro-antd/core/util";
import { concat, defer, scan, takeLast } from "rxjs";

export function RunCanActivateInSequence(guardsTokens: Array<CanActivateFn | any>): CanActivateFn {
    return (route, state) => {
        const injector = inject(EnvironmentInjector);
        const resolvedGuards = guardsTokens.map((t, i) => {
            const guard = resolveGuard(injector, t);
            return defer(() => {
                const guardVal = isFunction(guard) ? injector.runInContext(() => guard(route, state)) : guard.canActivate(route, state);
                return wrapIntoObservable(guardVal).pipe(takeLast(1));
            });
        });

        return concat(...resolvedGuards).pipe(
            scan((acc, res) => {
                if (acc instanceof UrlTree) return acc;
                if (acc === false) return false;
                return res;
            }),
            takeLast(1)
        );
    }
}

function resolveGuard(injector: Injector, tokenOrFunction: CanActivateFn | Type<CanActivate>): CanActivateFn | CanActivate {
    const NOT_FOUND = Symbol();
    const result = injector.get(tokenOrFunction, NOT_FOUND);
    if (result === NOT_FOUND) {
        if (typeof tokenOrFunction === 'function' && !isInjectable(tokenOrFunction)) {
            return tokenOrFunction as CanActivateFn;
        } else {
            return injector.get(tokenOrFunction);
        }
    }
    return result as CanActivate;
}