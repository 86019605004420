import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView } from '@cawita/core-front';
import { Subscription } from '@shared/models';
import { Subscription as RxSubscription } from 'rxjs';
import { ProConfigStore } from './pro-config.store';

export type CurrentSubscriptionContext = {
  $implicit: Subscription;
  cwtCurrentSubscription: Subscription;
}

@Directive({
  selector: '[cwtCurrentSubscription]'
})
export class CurrentSubscriptionDirective implements OnInit {
  private _ctx: CurrentSubscriptionContext = { $implicit: null, cwtCurrentSubscription: null };
  private _view = new CwtDirectiveView(this.vcRef, this.tRef, this._ctx);
  private _sub: RxSubscription;

  constructor(
    private vcRef: ViewContainerRef,
    private tRef: TemplateRef<CurrentSubscriptionContext>,
    private store: ProConfigStore
  ) { }

  ngOnInit(): void {
    this._subscribe();
  }

  private _subscribe() {
    this._sub?.unsubscribe();
    this._sub = this.store.stableState$.subscribe((state) => {
      this._ctx.$implicit = state.subscription;
      this._ctx.cwtCurrentSubscription = state.subscription;
      this._view.ensureState(true);
    });
  }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
  }

}
