import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cwt-app',
  template: `
    <ion-app>
      <ion-router-outlet></ion-router-outlet>
    </ion-app>
  `
})
export class AppComponent { }
