<cwt-icon-modal-wrapper icon="assets/img/star_full.svg">
    <cwt-simple-page class="ion-text-center">
        <h4 class="m-0 mb-3">{{'view.rate-pro' | translate}}</h4>
        <h6 class="m-0 mb-5">{{ 'alert.pro-rate' | translate }}</h6>
        <form nz-form nzLayout="vertical" [cwtFormSubmit]="form.get('rate')" (formValid)="modal.dismiss($event, 'confirm')" [formGroup]="form.get('rate')">
            <nz-form-item class="mb-3">
                <nz-form-label class="p-0">{{'field.professionalism' | translate}}</nz-form-label>
                <cwt-star-rating required formControlName="professionalism" size="32"></cwt-star-rating>
            </nz-form-item>
            <nz-form-item class="mb-3">
                <nz-form-label class="p-0">{{'field.quality-price' | translate}}</nz-form-label>
                <cwt-star-rating required formControlName="qualityPrice" size="32"></cwt-star-rating>
            </nz-form-item>
            <nz-form-item class="mb-3">
                <nz-form-label class="p-0">{{'field.quickness' | translate}}</nz-form-label>
                <cwt-star-rating required formControlName="quickness" size="32"></cwt-star-rating>
            </nz-form-item>
            <nz-form-item class="mb-3">
                <nz-form-label>{{'field.optional-comment' | translate}}</nz-form-label>
                <textarea nz-input rows="4" formControlName="comment" [placeholder]="'alert.comment-rate-pro' | translate"></textarea>
            </nz-form-item>
            <div class="d-flex flex-column justify-content-center align-items-center mt-3 gap-3">
                <ion-button expand="block" class="align-self-stretch" color="dark" type="submit" [disabled]="form.disabled">
                    <span>{{'action.submit' | translate}}</span>
                </ion-button>
                <ion-button color="medium" size="small" fill="clear" type="button" [disabled]="form.disabled" (click)="modal.dismiss(null, 'confirm')">
                    <span>{{'action.skip' | translate}}</span>
                </ion-button>
            </div>
        </form>
    </cwt-simple-page>
</cwt-icon-modal-wrapper>


<ng-template #error let-control>
    <span cwt-form-error [control]="control">
        <span *cwtFormError="'required'">{{'validation.required' | translate}}</span>
    </span>
</ng-template>