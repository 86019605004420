import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StarRatingComponent } from './star-rating.component';
import { AppCommonModule, AppFormModule } from '@shared/modules';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    StarRatingComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    AppFormModule,
    IonicModule
  ],
  exports: [
    StarRatingComponent
  ]
})
export class StarRatingModule { }
