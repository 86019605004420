import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CwtMediaViewerOptions, ICwtMediaViewerAdapter } from '@shared/modules/media/media-viewer-adapter';
import { MediaViewerComponent } from './media-viewer.component';

@Injectable()
export class MediaViewerService implements ICwtMediaViewerAdapter {
    constructor(private modal: ModalController) { }

    async viewMedia(options: CwtMediaViewerOptions): Promise<void> {
        const modal = await this.modal.create({
            component: MediaViewerComponent,
            componentProps: {
                mediaType: options?.type,
                src: options?.src,
                name: options?.name,
                onDelete: options?.onDelete,
            }
        });

        modal.present();
    }

}