import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActiveAddressDirective } from './active-address.directive';
import { ActiveAddressStore, initializeMyStore } from './active-address.store';
import { CWT_INIT_SEQUENCE } from '@cawita/core-front';



@NgModule({
  declarations: [
    ActiveAddressDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ActiveAddressDirective
  ]
})
export class ActiveAddressModule {
  static forRoot(): ModuleWithProviders<ActiveAddressModule> {
    return {
      ngModule: ActiveAddressModule,
      providers: [
        { provide: CWT_INIT_SEQUENCE, deps: [ActiveAddressStore], useFactory: initializeMyStore(), multi: true }
      ]
    }
  }
}
