import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cwt-centered-page',
  templateUrl: './centered-page.component.html',
  styleUrls: ['./centered-page.component.less'],
})
export class CenteredPageComponent implements OnInit {

  @Input() justify: string = 'center';
  @Input() align: string = 'stretch';

  constructor() { }

  ngOnInit(): void {
  }

}
