import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { CwtAuthStore } from '@cawita/core-front/auth';
import { MenuController, NavController } from '@ionic/angular';
import { CwtIonAlertService } from './alert.service';

@Directive({
  selector: '[cwtLogout]'
})
export class LogoutDirective {

  constructor(
    private alert: CwtIonAlertService,
    private auth: CwtAuthStore,
    private router: Router
  ) { }

  @HostListener('click', ['$event'])
  public async onClick(event: Event) {
    event?.stopPropagation();
    event?.preventDefault();
    const shouldLogout = await this.alert.confirm({
      title: 'alert.logout-title',
      message: 'alert.logout-message',
      confirmText: 'action.logout',
      cancelText: 'action.cancel',
      danger: true,
    });

    if (!shouldLogout) return;
    this.router.navigate(['/'], { replaceUrl: true });
    this.auth.logout(false);
  }
}
