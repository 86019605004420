import { CommonModule } from '@angular/common';
import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { Platform } from '@ionic/angular';
import { MARKET_ADAPTER } from './markets/market.adapter';
import { ManageSubscriptionDirective, MarketProviderDirective, ProductsForTypeDirective, PurchaseDirective } from './directives';
import { AppleMarketService, GoogleMarketService, NoopMarketService, StripeMarketService } from './markets';
import { RestorePurchaseDirective } from './directives/restore-purchase.directive';

function marketFactory(platform: Platform, injector: Injector) {
  const isNative = platform.is('capacitor');
  if (!isNative) return injector.get(StripeMarketService);
  if (platform.is('ios')) return injector.get(AppleMarketService);
  if (platform.is('android')) return injector.get(GoogleMarketService);
  return injector.get(NoopMarketService);
}

@NgModule({
  declarations: [
    ProductsForTypeDirective,
    PurchaseDirective,
    RestorePurchaseDirective,
    ManageSubscriptionDirective,
    MarketProviderDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ProductsForTypeDirective,
    PurchaseDirective,
    RestorePurchaseDirective,
    ManageSubscriptionDirective,
    MarketProviderDirective
  ]
})
export class MarketModule {
  static forRoot(): ModuleWithProviders<MarketModule> {
    return {
      ngModule: MarketModule,
      providers: [
        {
          provide: MARKET_ADAPTER,
          deps: [Platform, Injector],
          useFactory: marketFactory
        }
      ]
    }
  }
}
