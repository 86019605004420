import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, isset, sameId } from '@cawita/core-front';
import { Pro } from '@shared/models';
import { ProStore } from '@user/modules/mobile-pro';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[cwtCanEditPro]'
})
export class CanEditProDirective implements OnInit, OnDestroy {

    private _view = new CwtDirectiveView(this.vcRef, this.tRef, {});
    private _sub: Subscription;
    private _elseView = new CwtDirectiveView(this.vcRef, null, {});

    @Input('cwtCanEditPro') pro: Pro;
    @Input('cwtCanEditProElse') set elseTemplate(v: TemplateRef<any>) {
        this._elseView.setTemplate(v);
    }


    constructor(
        private vcRef: ViewContainerRef,
        private tRef: TemplateRef<any>,
        private proStore: ProStore
    ) { }

    ngOnInit(): void {
        this._sub = this.proStore.state$.subscribe((state) => {
            const proIsMine = (isset(state.pro) && sameId(state.pro, this.pro)) ? true : false;
            this._view.ensureState(proIsMine);
            this._elseView.ensureState(!proIsMine);
        })

    }

    ngOnDestroy(): void {
        this._sub?.unsubscribe();
    }

}
