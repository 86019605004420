import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView } from '@cawita/core-front';
import { Customer } from '@shared/models';
import { Subscription } from 'rxjs';
import { CustomerStore } from './customer.store';

export type ActiveCustomerContext = {
  $implicit: Customer;
  cwtActiveCustomer: Customer;
}

@Directive({
  selector: '[cwtActiveCustomer]'
})
export class ActiveCustomerDirective implements OnInit, OnDestroy {
  private _ctx: ActiveCustomerContext = { $implicit: null, cwtActiveCustomer: null };
  private _elseView = new CwtDirectiveView(this.vcRef, null, {});

  private _view = new CwtDirectiveView(this.vcRef, this.tRef, this._ctx);
  private _sub: Subscription;

  @Input('cwtActiveCustomerElse') set elseTemplate(v: TemplateRef<any>) {
    this._elseView.setTemplate(v);
}

  constructor(
    private vcRef: ViewContainerRef,
    private tRef: TemplateRef<ActiveCustomerContext>,
    private store: CustomerStore
  ) { }

  ngOnInit(): void {
    this._sub = this.store.state$.subscribe((state) => {
      this._ctx.$implicit = state.customer;
      this._ctx.cwtActiveCustomer = state.customer;
      this._view.ensureState(state.initialized && !!state.customer);
      this._elseView.ensureState(!state.customer);
    });
  }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
  }


}
