import { Directive, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { Device, DeviceInfo } from '@capacitor/device';
import { asArray, isset } from '@cawita/core-front';

@Directive({
  selector: '[cwtIsPlatform]'
})
export class IsPlatformDirective implements OnInit, OnChanges {

  @Input('cwtIsPlatform') platform: DeviceInfo['platform'] | DeviceInfo['platform'][];
  @Input('cwtIsPlatformElse') elseTemplate?: TemplateRef<any>;

  constructor(
    private vcRef: ViewContainerRef,
    private ref: TemplateRef<any>
  ) { }

  ngOnInit(): void {
    this._checkPlatformMatch(this.platform);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { platform } = changes;
    if (isset(platform) && !platform.isFirstChange()) this._checkPlatformMatch(this.platform);
  }

  private async _checkPlatformMatch(platformToMatch: DeviceInfo['platform'] | DeviceInfo['platform'][]) {
    const { platform } = await Device.getInfo();
    this.vcRef.clear();
    const platformsToMatch = asArray(platformToMatch);
    if (platformsToMatch.includes(platform)) this.vcRef.createEmbeddedView(this.ref);
    else if (isset(this.elseTemplate)) this.vcRef.createEmbeddedView(this.elseTemplate);
  }

}
