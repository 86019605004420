import { ChangeDetectionStrategy, Component, Input} from '@angular/core';



@Component({
  selector: 'cwt-horizontal-scrolled-list',
  templateUrl: './horizontal-scrolled-list.component.html',
  styleUrls: ['./horizontal-scrolled-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HorizontalScrolledListComponent{

  @Input() gap = 2;

  constructor(
  ) { }

}
