import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, hasSomeChanges } from '@cawita/core-front';
import { Pro, ProAcceptedFlows, ProStatus } from '@shared/models';
import { Subscription } from 'rxjs';
import { proAllowsFlow } from './shared-pro.utils';

@Directive({ selector: '[cwtProAllowsFlow]' })
export class ProAllowsFlowDirective implements OnChanges {
    private _view = new CwtDirectiveView(this.vcRef, this.tRef, {});
    private _elseView = new CwtDirectiveView(this.vcRef, null, {});
    private _sub: Subscription;

    @Input('cwtProAllowsFlow') pro: Pro;
    @Input('cwtProAllowsFlowFlow') flow: keyof ProAcceptedFlows | (keyof ProAcceptedFlows)[];
    @Input('cwtProAllowsFlowElse') set elseTemplate(v: TemplateRef<any>) {
        this._elseView.setTemplate(v);
    }

    constructor(
        private vcRef: ViewContainerRef,
        private tRef: TemplateRef<any>
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSomeChanges(changes, ['pro', 'status'], true)) this._update();
    }

    ngOnDestroy(): void {
        this._sub?.unsubscribe();
    }

    private _update() {
        const flowMatches = proAllowsFlow(this.pro, this.flow);
        this._view.ensureState(flowMatches);
        this._elseView.ensureState(!flowMatches);
    }
}