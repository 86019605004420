import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CWT_AUTH_LISTENER } from '@cawita/core-front/auth';
import { ActiveCustomerDirective } from './active-customer.directive';
import { CustomerStore } from './customer.store';

@NgModule({
  declarations: [
    ActiveCustomerDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ActiveCustomerDirective,
  ]
})
export class MobileCustomerModule {
  static forRoot(): ModuleWithProviders<MobileCustomerModule> {
    return {
      ngModule: MobileCustomerModule,
      providers: [
        { provide: CWT_AUTH_LISTENER, useExisting: CustomerStore, multi: true }
      ]
    }
  }
}
