import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, isset } from '@cawita/core-front';
import { Pro } from '@shared/models';
import { Subscription } from 'rxjs';
import { ProStore } from './pro.store';

export type ActiveProContext = {
  $implicit: Pro;
  cwtActivePro: Pro;
}

@Directive({
  selector: '[cwtActivePro]'
})
export class ActiveProDirective implements OnInit, OnDestroy {
  private _ctx: ActiveProContext = { $implicit: null, cwtActivePro: null };
  private _view = new CwtDirectiveView(this.vcRef, this.tRef, this._ctx);
  private _sub: Subscription;
  private _elseView = new CwtDirectiveView(this.vcRef, null, {});
  @Input('cwtActiveProElse') set elseTemplate(v: TemplateRef<any>) {
    this._elseView.setTemplate(v);
  }

  constructor(
    private vcRef: ViewContainerRef,
    private tRef: TemplateRef<ActiveProContext>,
    private store: ProStore
  ) { }

  ngOnInit(): void {
    this._sub = this.store.state$.subscribe((state) => {
      const proExists = isset(state.pro);
      this._ctx.$implicit = state.pro;
      this._ctx.cwtActivePro = state.pro;
      this._view.ensureState(proExists && state.initialized);
      this._elseView.ensureState(!proExists && state.initialized);

    });
  }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
  }


}
