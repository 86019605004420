import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CwtStrongAuthResponse, CwtStrongAuthStatus } from '@cawita/core-front/auth';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'cwt-strong-auth-modal',
  templateUrl: './strong-auth-modal.component.html',
  styleUrls: ['./strong-auth-modal.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StrongAuthModalComponent implements OnInit {

  public password = new FormControl(null);

  constructor(
    private modal: ModalController
  ) { }

  ngOnInit(): void {
  }

  cancel() {
    this.modal.dismiss({
      status: CwtStrongAuthStatus.Canceled,
      credentials: null
    } as CwtStrongAuthResponse);
  }

  submit() {
    if (!this.password.valid) return;
    this.modal.dismiss({
      status: CwtStrongAuthStatus.Success,
      credentials: {
        type: 'standard',
        password: this.password.value
      }
    } as CwtStrongAuthResponse);
  }
}
