import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AppFormModule } from '@shared/modules';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { MobileLayoutModule } from '../../layout/layout.module';
import { StarRatingModule } from '../star-rating/star-rating.module';
import { RateProDialogComponent } from './rate-pro-dialog.component';
import { CwtIonicModule } from '../../../../modules/ionic';

@NgModule({
  declarations: [
    RateProDialogComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    CwtIonicModule,
    AppFormModule,
    NzRateModule,
    MobileLayoutModule,
    StarRatingModule
  ],
  exports: [
    RateProDialogComponent,
  ]
})
export class RateProDialogModule {
}
