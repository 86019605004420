import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'cwt-grid-page',
  templateUrl: './grid-page.component.html',
  styleUrls: ['./grid-page.component.less']
})
export class GridPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
