import { coerceArray } from '@angular/cdk/coercion';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CwtI18nService, CwtTranslatable, isset, translateDictionary } from '@cawita/core-front';
import { AlertButton, AlertController, AlertInput, IonicSafeString } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

export type ChoiceAlertButton = {
    label: CwtTranslatable;
    role: string;
    style?: 'primary' | 'danger' | 'secondary';
}

export type SelectAlertOption<R> = {
    label: CwtTranslatable;
    value: R;
}

export type ConfirmationAlertConfig = {
    title: CwtTranslatable;
    subtitle?: CwtTranslatable;
    message: CwtTranslatable | CwtTranslatable[];
    confirmText?: CwtTranslatable;
    cancelText?: CwtTranslatable;
    danger?: boolean;
}

export type ShowAlertConfig = {
    title: CwtTranslatable;
    message: CwtTranslatable | CwtTranslatable[];
    closeText?: CwtTranslatable;
    danger?: boolean;
}

export type ChoiceAlertConfig = {
    title: CwtTranslatable;
    subtitle?: CwtTranslatable;
    message: CwtTranslatable | CwtTranslatable[];
    buttons: ChoiceAlertButton[];
}

export type SelectAlertConfig<R> = {
    title: CwtTranslatable;
    subtitle?: CwtTranslatable;
    message: CwtTranslatable | CwtTranslatable[];
    confirmText?: CwtTranslatable;
    cancelText?: CwtTranslatable;
    options: SelectAlertOption<R>[];
}

export type InputAlertConfig = {
    title: CwtTranslatable;
    subtitle?: CwtTranslatable;
    placeholder?: CwtTranslatable;
    type?: AlertInput['type'];
    message: CwtTranslatable | CwtTranslatable[];
    confirmText?: CwtTranslatable;
    cancelText?: CwtTranslatable;
}

@Injectable({ providedIn: 'root' })
export class CwtIonAlertService {
    constructor(
        private alert: AlertController,
        private translate: TranslateService,
        private i18n: CwtI18nService
    ) { }

    public async choose<R = string>(options: ChoiceAlertConfig): Promise<R> {
        const titleI18N = await this.translateString(options.title);
        const subtitleI18N = await this.translateString(options.subtitle);
        const messageI18N = await this.translateString(options.message);
        const buttons: AlertButton[] = [];
        for (const button of options.buttons) {
            const buttonI18N = await this.translateString(button.label);
            buttons.push({
                role: button.role,
                text: buttonI18N,
                cssClass: `alert-button-${button.style ?? 'default'}`
            })
        }

        const alert = await this.alert.create({
            header: titleI18N,
            subHeader: subtitleI18N,
            message: new IonicSafeString(messageI18N),
            cssClass: `cwt-alert`,
            animated: true,
            backdropDismiss: true,
            keyboardClose: false,
            buttons: buttons
        });

        alert.present();
        const results = await alert.onWillDismiss();
        return results.role as unknown as R;
    }

    public async select<R = string>(current: R, options: SelectAlertConfig<R>): Promise<R> {
        const titleI18N = await this.translateString(options.title);
        const subtitleI18N = await this.translateString(options.subtitle);
        const messageI18N = await this.translateString(options.message);
        const confirmI18N = await this.translateString(options.confirmText ?? 'action.confirm');
        const cancelI18N = await this.translateString(options.cancelText ?? 'action.cancel');
        const inputs: AlertInput[] = [];
        for (const option of options.options) {
            const optionsI18N = await this.translateString(option.label);
            inputs.push({
                label: optionsI18N,
                value: option.value,
                checked: current === option.value,
                type: 'radio'
            });
        }

        const alert = await this.alert.create({
            header: titleI18N,
            subHeader: subtitleI18N,
            message: new IonicSafeString(messageI18N),
            cssClass: `cwt-alert`,
            animated: true,
            backdropDismiss: false,
            keyboardClose: false,
            inputs: inputs,
            buttons: [
                { role: 'cancel', text: cancelI18N, cssClass: `alert-button-default` },
                { role: 'confirm', text: confirmI18N, cssClass: `alert-button-primary` }
            ]
        });

        await alert.present();
        const res = await alert.onWillDismiss();
        if (res.role !== 'confirm') return null;
        return res.data?.values;
    }

    public async confirm(options: ConfirmationAlertConfig): Promise<boolean> {
        const choice = await this.choose({
            title: options.title,
            subtitle: options.subtitle,
            message: options.message,
            buttons: [
                { label: options.cancelText ?? 'action.cancel', role: 'cancel' },
                { label: options.confirmText ?? 'action.confirm', role: 'confirm', style: options?.danger ? 'danger' : 'primary' },
            ]
        });

        if (choice === 'confirm') return true;
        return false;
    }

    public async show(options: ShowAlertConfig): Promise<void> {
        await this.choose({
            title: options.title,
            message: options.message,
            buttons: [
                { label: options.closeText ?? 'action.ok', role: 'confirm', style: options.danger ? 'danger' : 'primary' },
            ]
        });
    }

    public async httpError(httpError: HttpErrorResponse) {
        const lang = this.translate?.currentLang || this.translate?.defaultLang;
        const message = translateDictionary(httpError?.error?.translations, lang, 'api-error.default');
        return this.show({
            title: 'api-error.title',
            message: message,
            danger: false
        });
    }

    private async translateString(str: CwtTranslatable | CwtTranslatable[]): Promise<string> {
        const toTranslate = coerceArray(str)?.filter(isset);
        if (!isset(toTranslate) || !toTranslate?.length) return undefined;
        const strings = await Promise.all(toTranslate.map(t => lastValueFrom(this.i18n.get(t))));
        return strings.join('\n');
    }
}