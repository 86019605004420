import { Directive, EventEmitter, HostListener, Inject, Output } from '@angular/core';
import { CwtI18nService, CWT_COMMON_CONFIG, ICwtCommonConfig, isset } from '@cawita/core-front';
import { CwtAuthStore } from '@cawita/core-front/auth';
import { CustomerStore } from '../customer';
import { CwtIonAlertService } from './alert.service';

@Directive({ selector: '[cwtSelectLocale]' })
export class SelectLocaleDirective {

    @Output('cwtSelectLocale') selected = new EventEmitter<string>();

    constructor(
        @Inject(CWT_COMMON_CONFIG) private commonConfig: ICwtCommonConfig,
        public auth: CwtAuthStore,
        public customerStore: CustomerStore,
        private alert: CwtIonAlertService,
        private i18n: CwtI18nService
    ) { }


    @HostListener('click')
    async selectLanguage() {
        const res = await this.alert.select(this.i18n.currentLocale, {
            title: 'alert.select-language-title',
            message: 'alert.select-language-message',
            options: this.commonConfig.i18n.locales.map(locale => ({
                value: locale.localeId,
                label: locale.localeName,
            })),
        });

        if (!isset(res)) return;
        this.i18n.use(res).subscribe();
        this.selected.emit(res);
    }
}