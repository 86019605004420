import { Inject, Injectable } from '@angular/core';
import { CwtApiService, CwtCrudContract, CwtCrudService } from '@cawita/core-front/api';
import { ICwtAuthListener } from '@cawita/core-front/auth';
import { CwtStore } from '@cawita/core-front/state';
import { BestProConfig, Pro, ProConfig, Product, Subscription } from '@shared/models';
import { EMPTY, finalize, forkJoin, lastValueFrom, of, switchMap, tap } from 'rxjs';
import { AsProProApi } from '../../../../../frontend-shared/src/lib/services/apis';
import { IMarketListener } from '../market/providers/market-listener.provider';

export type ProConfigState = {
    config: BestProConfig;
    subscription: Subscription;
}

@Injectable({ providedIn: 'root' })
export class ProConfigStore extends CwtStore<ProConfigState> implements ICwtAuthListener, IMarketListener {
    constructor(
        private api: CwtApiService,
        private proApi: AsProProApi,
        @Inject(CwtCrudContract.get(Subscription, 'as-pro')) protected subscriptionCrud: CwtCrudService<Subscription>,
        @Inject(CwtCrudContract.get(ProConfig, 'as-pro')) protected proConfigCrud: CwtCrudService<ProConfig>,
    ) {
        super({
            initialized: false,
            loading: false,
            config: null,
            subscription: null
        });
    }

    onLogin(user: any): void {
        this._init().subscribe();
    }

    onLogout(): void {
        this.setState({ config: null, subscription: null, initialized: false, loading: false });
    }

    async onPurchaseComplete(product: Product): Promise<void> {
        await lastValueFrom(this._init());
        console.log('REFRESHED');
    }
    async onPurchaseRefreshed(): Promise<void> {
        await lastValueFrom(this._init());
        console.log('REFRESHED');
    }

    async onPurchaseFailed(product: Product): Promise<void> {
        await lastValueFrom(this._init());
        console.log('REFRESHED');
    }

    public refresh() {
        return this._init();
    }

    public replace(pro: Pro) {
        this.setState(c => ({
            ...c,
            pro
        }));
    }

    private _init() {
        this.setInitialized(false);
        return this.api.get('as-pro/pro/has-pro').pipe(
            switchMap((hasPro: boolean) => hasPro ? this._getData() : EMPTY)
        ).pipe(
            finalize(() => this.setInitialized(true)),
            tap(([config, subscription]) => this.setState(c => ({
                ...c,
                config,
                subscription
            })))
        );
    }

    private _getData() {
        return forkJoin([
            this.proApi.getProConfig(),
            this.subscriptionCrud.getById('current')
        ]);
    }
}