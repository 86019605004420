import { Inject, Injectable } from '@angular/core';
import { CwtApiService, CwtCrudContract, CwtCrudService } from '@cawita/core-front/api';
import { ICwtAuthListener } from '@cawita/core-front/auth';
import { CwtStore } from '@cawita/core-front/state';
import { Notification, Pro } from '@shared/models';
import { finalize, of, switchMap, tap } from 'rxjs';
import { SimpleNotificationContract, SimpleNotificationService } from '@shared/services/apis/contracts/simple-notification-contract';
import { CUSTOMER_LISTENER } from '../customer/customer-listener';
import { IProListener, PRO_LISTENER } from './pro-listener';

export type ProState = {
    pro: Pro;
}

@Injectable({ providedIn: 'root' })
export class ProStore extends CwtStore<ProState> implements ICwtAuthListener {
    constructor(
        private api: CwtApiService,
        @Inject(CwtCrudContract.get(Pro, 'as-pro')) protected proCrud: CwtCrudService<Pro>,
        @Inject(PRO_LISTENER) protected listeners: IProListener[],
    ) {
        super({
            initialized: false,
            loading: false,
            pro: null
        });
    }

    onLogin(user: any): void {
        this._init().subscribe();
    }

    onLogout(): void {
        this.setState({ pro: null, initialized: false, loading: false });
        this.listeners?.forEach(l => l.proInitialized(null));
    }

    public refresh() {
        return this._init();
    }

    public replace(pro: Pro) {
        this.setState(c => ({
            ...c,
            pro
        }));
    }

    private _init() {
        this.setInitialized(false);
        return this.api.get('as-pro/pro/has-pro').pipe(
            switchMap((hasPro: boolean) => {
                if (hasPro) return this.proCrud.getById('mine', { options: { projection: 'forUserProDetails' } });
                return of(null);
            })
        ).pipe(
            finalize(() => this.setInitialized(true)),
            tap(pro => {
                this.listeners?.forEach(l => l.proInitialized(pro));
                this.setState(c => ({ ...c, pro }));
            })
        );
    }
}